import React, { useEffect, useState } from "react"
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from "react-bootstrap";

import CodeEditor from "../code_editor";

const Show = (props) => {
  const { sampleData, dataUrl, updateUrl, backUrl, csrfToken, printerName } = props;

  const [content, setContent] = useState(null);
  const [showSample, setShowSample] = useState(false);

  // Load initial data
  useEffect(async () => {
    if (!dataUrl) { return }

    const response = await fetch(dataUrl)
    const text = await response.text();
    setContent(text);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(updateUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ data: content })
    })
      .then(() => toast.success('Updated printer print definition successfully'))
      .catch(() => toast.error("Error occurred while updating printer print definition"));
  };

  const handleEditorChange = (text) => {
    setContent(text);
  };

  return (
    <div className="content">
      <div className="block block-rounded block-bordered">
        <div className="block-header block-header-default">
          <h3 className="block-title">Print Definitions: {printerName}</h3>

          <div className="block-options">
            <div className="block-options-item">
              <a href="#" onClick={() => setShowSample(true)} className="text-capitalize text-info font-w600" role="button"><u>View Sample</u></a>
              <a href={updateUrl} onClick={handleSubmit} className="text-capitalize text-info font-w600 ml-2" role="button"><u>Update</u></a>
              <a href={backUrl} className="text-capitalize text-info font-w600 ml-2" role="button"><u>Back</u></a>
            </div>
          </div>
        </div>
        <div className="block-content">
          <CodeEditor mode='json' initialContent={content} handleEditorChange={handleEditorChange} />
        </div>
      </div>
      <Toaster position="top-right" />
      <Modal show={showSample} onHide={() => setShowSample(false)} size="lg">
        <Modal.Header>
          <Modal.Title>Sample Printer Print Definition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{sampleData}</pre>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Show;
